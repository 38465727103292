import React, { Component } from "react";
import "../layouts/index.css";

import mixpanel from "../lib/mixpanel/index";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { SectionBox } from "../components/home";
import CallToAction from "../components/cta";
import { handleScroll } from "../lib/utils";
import RequestDemo from "../components/product/RequestDemo";

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: false,
      isRequestDemoOpen: false,
      email: "",
    };
  }

  componentDidMount() {
    mixpanel.track("HOME_PAGE_OPENED");
  }

  handleRequestDemo = (event) => {
    // event.preventdefault()
    this.setState(
      (prevState) => ({
        isRequestDemoOpen: !prevState.isRequestDemoOpen,
      }),
      () => handleScroll(this.state.isRequestDemoOpen)
    );
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  render() {
    const headingClassName = "ma0 pa0 f3 f2-ns tc tl-l fw-600";
    const descriptionClassName = "f4 f3-ns ma0 mt4 mt10-ns tc tl-l fw-normal";
    const sideClassName = "col-12 col-6-l mt6 mt0-l";
    const sideImgClassName = "section-image";
    const bodyClassName = "col-12 col-6-l mw-s-l align-self-center";
    const sectionClassName = "pt20 pb10 pt30-ns pb30-ns";
    return (
      <Layout headerClass="bg-color">
        <SEO title="Contact free, hygienic payments to your favorite shops and restaurants" />
        <div className="main-content">
          {this.state.isRequestDemoOpen ? (
            <RequestDemo
              email={this.state.email}
              isOpen={this.state.isRequestDemoOpen}
              handleModal={this.handleRequestDemo}
            />
          ) : null}
          <SectionBox
            heading={
              <span>
              Contact free, hygienic payments to your favorite shops and restaurants
              </span>
            }
            headingClass="ma0 pa0 f2 f1-ns tc tl-l fw-bold"
            sideImg="home/home-hero.svg"
            bodyClass="col-12 col-5-l flex flex-column"
            sideImgClassName="hero-image"
            sideClass={`${sideClassName} col-7-l justify-self-center justify-self-end-l`}
            className="hero-container"
          >
            <p className="f4 f3-ns ma0 mt4 mt10-ns tc tl-l fw-normal">
              Simply scan your local business’ Loqal Code with your camera, and
              make your payment.
            </p>
            <div className="flex flex-column flex-row-ns mt8">
              <input
                className="input hero-input mr4 mb4"
                placeholder="Your Email"
                onChange={(event) => this.handleEmailChange(event)}
              />
              <button
                className="btn-base btn-contained f5"
                style={{ minWidth: 150, height: 60 }}
                onClick={this.handleRequestDemo}
              >
                Early Access
              </button>
            </div>
          </SectionBox>
          <SectionBox
            heading="Everything via your phone"
            sideImg="home/chapter1.svg"
            headingClass={headingClassName}
            sideImgClassName={sideImgClassName}
            sideClass={`${sideClassName} o2 o1-l justify-self-center justify-self-start-l`}
            bodyClass={`${bodyClassName} o1 o2-l justify-self-center justify-self-end-l`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              Loqal is absolutely free, and completely managed in your phone -
              keep jamming to your favorite tune while paying for your pizza.
            </p>
          </SectionBox>
          <SectionBox
            heading="More secure than cards"
            sideImg="home/chapter2.svg"
            headingClass={headingClassName}
            sideImgClassName={sideImgClassName}
            sideClass={`${sideClassName} justify-self-center justify-self-end-l`}
            bodyClass={`${bodyClassName}`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              You use built-in authentication in your phone like touch ID or
              face ID to authenticate your payments.
            </p>
          </SectionBox>
          <SectionBox
            heading="Get awesome rewards"
            sideImg="home/chapter3.svg"
            headingClass={headingClassName}
            sideImgClassName={sideImgClassName}
            sideClass={`${sideClassName} o2 o1-l justify-self-center justify-self-start-l`}
            bodyClass={`${bodyClassName} o1 o2-l justify-self-center justify-self-end-l`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              Get personalized discounts from your favorite local stores and
              restaurants every time you pay with Loqal.
            </p>
          </SectionBox>
          <SectionBox
            heading="Support your local businesses"
            sideImg="home/chapter4.png"
            headingClass={headingClassName}
            sideImgClassName={sideImgClassName}
            sideClass={`${sideClassName} justify-self-center justify-self-end-l`}
            bodyClass={`${bodyClassName}`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              When you pay via Loqal you help your local businesses to get more
              cash instead of losing on high transaction fees to POS providers.
            </p>
          </SectionBox>
          <CallToAction onButtonClick={this.handleRequestDemo} />
        </div>
      </Layout>
    );
  }
}
export default IndexPage;
